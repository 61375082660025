export default () => ({
  loading: false,
  currencyCode: null,
  summary: [],
  summary_totals: {

  },
  summary_cols: [],
  dashboard_reports: [],
  rows_before_limit_at_least: 0,
  dashboardRows: 0,
  chart: [],
  chartmini: [],
  earnings: [],
  supported_dimentions: [],
  supported_metrics: [],
  totals: {},
  promocode_title: '',
  cpa_conversions: [],
  cpa_conversions_count: 0,

  metrics: {
    options: [],
    groups: [],
    selection: [],
    defs: {},
    groupped: [],
  },
  filters: {
    daily: {
      options_traffic: {
        traffic_sources: [],
        countries: [],
        players: [],
        offers: [],
        subids1: [],
        subids2: [],
        subids3: [],
        subids4: [],
        subids5: [],
      },
      options_subaffiliate: {
        sub_affiliate_id: '',
        traffic_sources: [],
        countries: [],
        players: [],
        offers: [],
        subids1: [],
        subids2: [],
        subids3: [],
        subids4: [],
        subids5: [],
      },
    },
    summary: {
      options_traffic: {
        traffic_sources: [],
        countries: [],
        players: [],
        offers: [],
        subids1: [],
        subids2: [],
        subids3: [],
        subids4: [],
        subids5: [],
      },
      options_subaffiliate: {
        sub_affiliate_id: '',
        traffic_sources: [],
        countries: [],
        players: [],
        offers: [],
        subids1: [],
        subids2: [],
        subids3: [],
        subids4: [],
        subids5: [],
      },
    },
    players: {
      subids1: [],
      subids2: [],
      subids3: [],
      subids4: [],
      subids5: [],
    },
  },

  SUMMARY_COLS: [
    'impressions_count',
    'visits_count',
    'net_profit_sum',
    'revenue_sum',
    'registrations_count',
    'deposits_first_count',
    'deposits_all_count',
    'players_with_deposit_count',
    'players_with_first_deposit_count',
    'deposits_first_sum',
    'deposit_bonus_count',
    'deposit_bonus_sum',
    'players_with_bet_count',
    'bet_new_sports_count',
    'bet_result_sports_count',
    'bet_result_sports_profit_sum',
    'bet_new_games_count',
    'bet_result_games_count',
    'bet_result_games_profit_sum',
    'bet_sell_count',
    'bet_sell_profit_sum',
    'bet_insurance_count',
    'bet_insurance_sum',
    'bet_insurance_result_count',
    'bet_insurance_result_sum',
  ],
});
