<template>
  <div
    :style="{ width: getSelectWidth }"
    :class="[
      size,
      {'open': isOpen},
      {'disabled': disabled},
      {'filterable': filterable},
      {'multiple': multiple},
      {'labeled': label},
    ]"
    class="ui-select"
  >
    <span
      v-if="label"
      class="label"
      @click="togglePop"
    >
      {{ label }}
    </span>
    <div
      class="control-wrapper"
      @click="togglePop"
    >
      <div class="tags">
        <template v-if="$_.isNil(selectedOptions[0])">
          <span class="placeholder">{{ placeholder }}</span>
        </template>
        <template v-else>
          <template v-if="!multiple">
            <div class="value">
              <span class="label">{{ labelI18n ? $t(`${labelI18n}.${selectedOptions[0][labelProp]}`) : selectedOptions[0][labelProp] }}</span>
              <ui-icon
                :size="8"
                name="delete"
                class="del"
                @click.native="removeOption($event, null)"
              />
            </div>
          </template>
          <template v-else>
            <div
              :class="{'with-count': value.length > 1}"
              class="value"
            >
              <span class="label">{{ selectedOptions[0] ? labelI18n ? $t(`${labelI18n}.${selectedOptions[0][labelProp]}`) : selectedOptions[0][labelProp] : '' }}</span>
              <ui-icon
                :size="8"
                name="delete"
                class="del"
                @click.native="removeOption($event, selectedOptions[0])"
              />
            </div>
            <div
              v-show="value.length > 1"
              class="value count"
            >
              <span class="label"> +{{ value.length - 1 }}</span>
            </div>
          </template>
        </template>
      </div>
      <ui-icon
        :size="4"
        name="arrow-down"
        class="dd-icon"
      />
    </div>
    <transition name="fade">
      <div
        v-if="!disabled"
        v-show="isOpen"
        class="pop"
      >
        <div class="arrow" />
        <div class="options">
          <ui-input
            v-if="filterable"
            ref="filter"
            v-model="filterQuery"
            :placeholder="'Filter options'"
            :tabindex="-1"
            class="filter"
            borderless
            clearable
            autosize
            @input="getOptions"
          />
          <div
            v-loading="remote && isOptionsLoading"
            class="content"
          >
            <div
              ref="list"
              class="items"
            >
              <template v-if="multiple && selectedOptions.length > 0">
                <div class="tags">
                  <div
                    v-for="(option, index) in selectedOptions"
                    :key="index"
                    class="tag"
                  >
                    <span class="label">{{ labelI18n ? $t(`${labelI18n}.${option[labelProp]}`) : option[labelProp] }}</span>
                    <ui-icon
                      :size="8"
                      name="delete"
                      class="del"
                      @click.native="removeOption($event, option)"
                    />
                  </div>
                </div>
              </template>
              <div
                v-for="(option, index) in filterNotSelectedOptions(showingOptions)"
                :key="index"
                :class="{'selected': isOptionSelected(option)}"
                class="item"
                @click="selectOption(option)"
              >
                <span class="text">{{ labelI18n ? $t(`${labelI18n}.${option[labelProp]}`) : option[labelProp] }}</span>
              </div>
            </div>
            <span
              v-show="$_.isEmpty(filterNotSelectedOptions(showingOptions))"
              class="no-opts"
            >{{ noOptsText }}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'UiSelect',
  props: {
    value: {
      type: [String, Number, Array, Object],
      default: '',
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultValue: {
      type: [String, Number, Array, Object],
      default: '',
    },
    excludeOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    labelProp: {
      type: String,
      default: 'label',
    },
    labelI18n: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: 180,
    },
    placeholder: {
      type: String,
      default: 'Select item',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    multiTags: {
      type: Boolean,
      default: false,
    },
    tagsLimit: {
      type: Number,
      default: 0,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    remote: {
      type: Boolean,
      default: false,
    },
    filterMethod: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      filterQuery: '',
      isOptionsLoading: false,
      showingOptions: [],
      selectedOptions: [],
    };
  },
  computed: {
    getSelectWidth() {
      if (this.$_.isString(this.width)) {
        return this.width;
      }
      if (this.$_.isNumber(this.width) && this.width > 0) {
        return `${this.width}px`;
      }
      return '100%';
    },
    noOptsText() {
      return this.filterQuery ? 'No matching options' : 'No options';
    },
  },
  watch: {
    isOpen(nv) {
      if (nv) {
        if (this.filterable) {
          setTimeout(() => {
            this.$refs.filter.focus();
          }, 100);
        }
      }
      setTimeout(() => {
        this.$refs.list.scrollTop = 0;
      }, 300);
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.initSelectedOptions();
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler(nv, ov) {
        if (!this.$_.isEqual(nv, ov)) {
          this.initSelectedOptions();
        }
        if (this.remote) {
          this.getOptions();
        } else {
          this.showingOptions = this.$_.clone(this.options);
        }
      },
    },
  },
  mounted() {
    document.addEventListener('click', this.clickOutside, true);
    this.$el.parentElement.addEventListener('click', this.clickOutside);
    if (this.remote) {
      this.getOptions();
    } else {
      this.showingOptions = this.$_.clone(this.options);
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside);
  },
  methods: {
    initSelectedOptions() {
      if (!this.$_.isEmpty(this.options) || !this.$_.isEmpty(this.showingOptions)) {
        this.selectedOptions = [];
        if (this.multiple) {
          this.value.forEach((val) => {
            this.selectedOptions.push(this.options.find(op => op[this.valueProp] === val)
              || this.showingOptions.find(op => op[this.valueProp] === val)
              || this.getCacheRemoteOption(val));
          });
        } else {
          this.selectedOptions.push(this.options.find(opt => opt[this.valueProp] === this.value));
        }
      }
    },
    getOptions() {
      let ops;
      if (this.remote) {
        this.isOptionsLoading = true;
        this.filterMethod(this.filterQuery)
          .then((response) => {
            ops = response;
            if (this.selectedOptions.length > 0 && this.multiple) {
              ops = this.sortOptions(ops);
            }
            this.showingOptions = this.$_.clone(ops);
            this.isOptionsLoading = false;
          })
          .catch((error) => {
            ops = [];
            this.showingOptions = this.$_.clone(ops);
            this.isOptionsLoading = false;
            console.error(error);
          });
      } else if (this.filterQuery) {
        if (this.filterMethod) {
          ops = this.filterMethod(this.filterQuery);
        } else {
          ops = this.defaultFilterMethod();
        }
      } else {
        ops = this.options;
      }
      if (this.selectedOptions.length > 0 && this.multiple) {
        ops = this.sortOptions(ops);
      }
      this.showingOptions = this.$_.clone(ops);
    },
    sortOptions(ops) {
      return this.$_.sortBy(ops, [this.labelProp, this.valueProp]);
    },
    filterNotSelectedOptions(ops) {
      let filtered = this.$_.difference(ops, this.selectedOptions);
      filtered = this.$_.differenceWith(filtered, this.excludeOptions, (op, ex) => op[this.valueProp] === ex);
      return filtered;
    },
    clickOutside(e) {
      if (this.disabled) {
        return;
      }

      if (e.target.parentElement && !this.$el.contains(e.target)) {
        this.isOpen = false;
      }
    },
    selectOption(option) {
      if (this.disabled) {
        return;
      }
      let val;
      if (this.multiple) {
        val = this.value;
        const i = this.value.indexOf(option[this.valueProp]);
        if (i < 0) {
          val.push(option[this.valueProp]);
          this.selectedOptions.push(option);
        } else {
          this.$_.remove(val, opt => opt === option[this.valueProp]);
        }
        val.sort();
      } else {
        val = option[this.valueProp];
        this.togglePop();
      }
      if (this.remote) {
        this.setCacheRemoteOption(option);
      }
      this.initSelectedOptions();
      this.$emit('pick', option);
      this.$emit('select', val);
      this.$emit('input', val);
    },
    isOptionSelected(option) {
      if (this.multiple) {
        return this.value.indexOf(option[this.valueProp]) > -1;
      }
      return this.value === option[this.valueProp];
    },
    defaultFilterMethod() {
      const q = this.filterQuery.toLowerCase();
      return this.options.filter(option => option[this.labelProp].toLowerCase().indexOf(q) > -1 || option[this.valueProp].toString().toLowerCase().indexOf(q) > -1);
    },
    togglePop() {
      if (this.disabled) {
        return;
      }
      this.isOpen = !this.isOpen;
      this.filterQuery = '';
      this.getOptions();
    },
    removeOption(e, option) {
      e.preventDefault();
      e.stopPropagation();
      if (this.disabled) {
        return;
      }
      let val;
      if (this.multiple) {
        val = this.value;
        this.$_.remove(val, opt => opt === option[this.valueProp]);
        if (this.$_.isEmpty(val)) {
          val = this.defaultValue;
        }
      } else {
        val = this.defaultValue || '';
      }
      if (this.remote) {
        this.removeCacheRemoteOption(option);
      }
      this.initSelectedOptions();
      this.$emit('select', val);
      this.$emit('input', val);
    },
    setCacheRemoteOption(option) {
      localStorage.setItem(`selectOption/${option[this.valueProp]}`, JSON.stringify(option));
    },
    removeCacheRemoteOption(option) {
      localStorage.removeItem(`selectOption/${option[this.valueProp]}`);
    },
    getCacheRemoteOption(value) {
      return JSON.parse(localStorage.getItem(`selectOption/${value}`));
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'src/assets/theme/default/components/UiSelect';
</style>
