<template>
  <div class="col-md-12 ml-auto mr-auto registration">
    <div class="login-logo">
      <img :src="logo" alt="logo" />
    </div>

    <form :class="{ loading: loading, error: error }" @submit.prevent>
      <card class="pt-20 card-login">
        <section>
          <div class="row row-r">
            <div class="col-md-12 col-12">
              <h3 class="m-0 registration__title">
                {{ $t('registration.get_started') }}
              </h3>
            </div>
            <div class="col-md-12 col-12 registration__login-back login-back-stik">
              <span class="login-back-stik__title">{{ $t('registration.already_acc') }} </span>
              <router-link to="/" class="login-back-stik__link">{{ $t('registration.log_in') }}</router-link>
              <language-select />
            </div>
          </div>
          <div class="row">
            <p class="col-12 registration__sub-title">
              {{ $t('registration.start_work') }}
            </p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h6 class="input-title required">
                {{ $t('customReports.e_mail') }}
              </h6>
              <input
                v-model="model.email"
                :placeholder="$t('forms.emailPlaceholder')"
                type="email"
                class="login-input"
                @focus="emailValidation = false"
                @blur="emailValidation = true"
                @input="errors_.email = ''"
              />
              <div v-if="errors_.email" class="text-danger text-right">
                {{ !errors_.email ? $t('forms.validation.email') : errors_.email[0].message }}
              </div>
            </div>
            <div class="col-md-6">
              <h6 class="input-title required">
                {{ $t('profile.generalInfo.currency') }}
              </h6>
              <v-select
                class="v-select mb-16"
                id="currency"
                v-model="model.currency_code"
                :options="customerCurrencies"
                :multiple="false"
                :settings="{ minimumResultsForSearch: 6 }"
              />
            </div>
            <div class="col-md-6">
              <h6 class="input-title required">
                {{ $t('profile.generalInfo.password') }}
              </h6>
              <div class="registration__password-wrap">
                <input
                  v-model="model.password"
                  placeholder="********"
                  :type="isShowPassword ? 'text' : 'password'"
                  class="login-input"
                  @input="errors_.password = ''"
                />
                <div class="text-right eye-icon" style="height: 0px">
                  <i
                    :class="{ 'fa-eye': !isShowPassword, 'fa-eye-slash': isShowPassword }"
                    class="fas fa-eye text-muted"
                    @click="isShowPassword = !isShowPassword"
                  />
                </div>
              </div>
              <div v-if="errors_.password !== ''" class="text-danger text-right">
                {{ errors_.password[0].message }}
              </div>
            </div>
            <div class="col-md-6">
              <h6 class="input-title required">
                {{ $t('registration.password_confirm') }}
              </h6>
              <div class="registration__password-wrap">
                <input
                  v-model="model.password2"
                  placeholder="********"
                  :type="isShowPasswordConf ? 'text' : 'password'"
                  class="login-input"
                  @input="errors_.password2 = ''"
                />
                <div class="text-right eye-icon" style="height: 0px">
                  <i
                    :class="{ 'fa-eye': !isShowPasswordConf, 'fa-eye-slash': isShowPasswordConf }"
                    class="fas fa-eye text-muted"
                    @click="isShowPasswordConf = !isShowPasswordConf"
                  />
                </div>
              </div>
              <div v-if="password2Error" class="text-danger text-right">
                {{ password2Error }}
              </div>
            </div>
            <div class="col-md-6">
              <h6 class="input-title required">
                {{ $t('profile.generalInfo.firstName') }}
              </h6>
              <input
                v-model="model.first_name"
                :placeholder="$t('registration.placeholder_firstname')"
                type="email"
                class="login-input"
                @input="errors_.first_name = ''"
              />
              <div v-if="errors_.first_name !== ''" class="text-danger text-right">
                {{ errors_.first_name[0].message }}
              </div>
            </div>
            <div class="col-md-6">
              <h6 class="input-title required">
                {{ $t('registration.surname') }}
              </h6>
              <input
                v-model="model.last_name"
                :placeholder="$t('registration.placeholder_surname')"
                type="email"
                class="login-input"
                @input="errors_.last_name = ''"
              />
              <div v-if="errors_.last_name !== ''" class="text-danger text-right">
                {{ errors_.last_name[0].message }}
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="no-border registration__datepicker">
                <h6 class="input-title required">
                  {{ $t('profile.generalInfo.birthDate') }}
                </h6>
                <el-date-picker
                  class="date-picker datepicker-input p-0"
                  ref="datepicker"
                  :placeholder="$t('registration.placeholder_birth')"
                  v-model="model.date_of_birth"
                  type="date"
                  format="dd MMMM yyyy"
                  :default-value="defaultDateView"
                  :editable="false"
                  :picker-options="pickerOptions"
                  :clearable="false"
                  @change="errors_.date_of_birth = ''"
                />
              </div>
              <div v-if="errors_.date_of_birth !== ''" class="text-danger text-right">
                {{ errors_.date_of_birth[0].message }}
              </div>
            </div>

            <!--contact number-->

            <div class="col-md-6 col-12 phone-input">
              <h6 class="input-title">
                {{ $t('profile.generalInfo.contactNumber') }}
              </h6>
              <fg-input
                ref="phoneInput"
                v-model="model.phone_number"
                :placeholder="$t('registration.placeholder_number')"
                class="no-border form-control-lg"
                @keyup="formatTel"
              />
              <div v-if="errors_.phone_number !== ''" class="text-danger text-right">
                {{ errors_.phone_number[0].message }}
              </div>
            </div>

            <!--messenger-->

            <div class="col-md-6 col-12">
              <h6 class="input-title required">
                {{ $t('profile.generalInfo.messenger') }}
              </h6>
              <v-select
                class="v-select"
                id="messenger"
                v-model="model.messenger"
                :options="messengers"
                :multiple="false"
                :placeholder="$t('registration.placeholder_messenger')"
              />
              <div v-if="errors_.messenger !== ''" class="text-danger text-right ui-m-sm-t">
                {{ errors_.messenger[0].message }}
              </div>
            </div>

            <div class="col-md-6 col-12 registration__messenger-id">
              <h6 class="input-title required">
                {{ $t('profile.generalInfo.messengerId') }}
              </h6>
              <input
                v-model="model.messenger_id"
                :placeholder="messengerIdPlaceholder"
                type="email"
                class="login-input"
                @input="errors_.messenger_id = ''"
              />
              <div v-if="errors_.messenger_id !== ''" class="text-danger text-right">
                {{ errors_.messenger_id[0].message }}
              </div>
            </div>

            <div class="col-md-6 col-12 registration__country">
              <h6 class="input-title required">
                {{ $t('profile.generalInfo.country') }}
              </h6>
              <v-select
                class="v-select"
                id="country"
                v-model="model.country_code"
                :options="countries"
                :multiple="false"
                :placeholder="$t('registration.placeholder_country')"
              />

              <div v-if="errors_.country_code !== ''" class="text-danger text-right">
                {{ errors_.country_code[0].message }}
              </div>
            </div>

            <div class="col-md-6 col-12 registration__city">
              <h6 class="input-title">
                {{ $t('profile.generalInfo.city') }}
              </h6>
              <input
                v-model="model.city"
                :placeholder="$t('registration.placeholder_city')"
                type="email"
                class="login-input"
                @input="errors_.city = ''"
              />
              <div v-if="errors_.city !== ''" class="text-danger text-right">
                {{ errors_.city[0].message }}
              </div>
            </div>
            <div class="col-md-6 col-12 mb-16">
              <h6 class="input-title">
                {{ $t('profile.generalInfo.address') }}
              </h6>
              <input
                v-model="model.address"
                :placeholder="$t('profile.generalInfo.address')"
                class="login-input"
                :class="'overflow-ellipsis'"
                @input="errors_.address = ''"
              />
              <div v-if="errors_.address" class="text-danger text-right">
                {{ errors_.address[0].message }}
              </div>
            </div>
            <div class="col-md-6 col-12 mb-16">
              <h6 class="input-title">
                {{ $t('profile.generalInfo.postal_code') }}
              </h6>
              <input
                v-model="model.postal_code"
                :placeholder="$t('profile.generalInfo.postal_code')"
                class="login-input"
                @input="errors_.postal_code = ''"
              />
              <div v-if="errors_.postal_code" class="text-danger text-right">
                {{ errors_.postal_code[0].message }}
              </div>
            </div>
          </div>
          <div class="row" v-show="isCaptchaV2Required">
            <div class="col-12 captcha">
              <vue-recaptcha
                @verify="verifyV2"
                ref="captchaV2"
                :sitekey="$store.state.misc.miscSettings.recaptcha.public_key_v2"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="registration__agree agree-check">
                <div class="checkbox payment-history__checkbox" slot="actionsAppend">
                  <input v-model="payouts" id="checkbox1" type="checkbox" class="agree-check__input" />
                  <label
                    class="checkbox-label agree-check__label"
                    :class="{
                      payouts: payouts,
                    }"
                    for="checkbox1"
                  >
                  </label>
                  <label class="agree-check__label-p lppp">
                    {{ $t('registration.agree.1') }}
                    <a
                      :href="rules.tos_url"
                      :class="{ 'pasive-p': !rules.tos_url }"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="agree-check__accent-p"
                    >
                      {{ $t('registration.agree.2') }}
                    </a>
                    {{ rules.privacy_policy_url && '&' }}
                    <a
                      v-if="rules.privacy_policy_url"
                      :href="rules.privacy_policy_url"
                      class="agree-check__accent-p"
                      :class="{ 'pasive-p': !rules.privacy_policy_url }"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ $t('registration.agree.3') }}
                    </a>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <button
                v-loading="loading"
                :disabled="(isCaptchaV2Required && !recaptchaTokenV2) || loading || !payouts"
                class="btn btn-primary btn-block big login btn-md"
                @click.prevent="handlerSubmit({ isBtnClick: true })"
              >
                {{ $t('forms.btn.submit') }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="registration__footer-text">
                {{ $t('registration.captcha.1') }}
                <a
                  href="https://policies.google.com/privacy"
                  class="agree-check__accent-p"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ $t('registration.captcha.2') }}
                </a>
                {{ $t('registration.captcha.3') }}
                <a
                  href="https://policies.google.com/terms"
                  class="agree-check__accent-p"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ $t('registration.captcha.4') }}.
                </a>
              </p>
            </div>
          </div>
        </section>
      </card>
      <div class="loading__block">
        <i class="fa fa-spinner fa-3x fa-fw login__spinner" />
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import 'intl-tel-input/build/js/utils';
import TelInput from 'intl-tel-input';
import cookieProvider from 'src/lib/cookieProvider';
import LanguageSelect from './components/LanguageSelect.vue';
import moment from 'moment';
import _ from 'lodash';


export default {
  components: { LanguageSelect },
  data() {
    return {
      isCaptchaV2Required: false,
      recaptchaTokenV2: null,
      recaptchaTokenV3: null,
      emailValidation: false,
      passwordValidation: false,
      loading: false,
      tel: TelInput,
      model: {
        email: '',
        password: '',
        password2: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        country_code: '',
        city: '',
        messenger: '',
        messenger_id: '',
        referred_by: '',
        address: '',
        postal_code: '',
        currency_code: '',
      },
      country_code: '',
      payouts: true,
      isShowPassword: false,
      isShowPasswordConf: false,
      errors_: {
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        phone_number: '',
        country_code: '',
        city: '',
        messenger: '',
        messenger_id: '',
        referred_by: '',
        recaptcha_token: '',
        address: '',
        postal_code: '',
        password2: '',
      },

      defaultDateView: moment()
        .subtract(18, 'year')
        .format('YYYY-MM-DD'),
      pickerOptions: {
        disabledDate(time) {
          return time.setFullYear(time.getFullYear() + 18) > Date.now();
        },
      },
    };
  },

  created() {
    this.$store.dispatch('getCountries');
    this.$store.dispatch('getMessengers');
    this.getGeoData();
    this.getCustomerCurrencies();
  },

  computed: {
    password2Error() {
      return _.get(this.errors_, ['password2', 0, 'message']) || _.get(this.errors_, ['password', 0, 'message'])
    },
    messengerIdPlaceholder() {
      if (this.model.messenger) {
        return (
          this.$store.state.misc.messengers.find((e) => e.id === this.model.messenger)?.example_placeholder ||
          this.$t('registration.placeholder_messenger_id')
        );
      }
      return this.$t('registration.placeholder_messenger_id');
    },
    rules() {
      return this.$store.getters.getterMiscSetting.rules;
    },

    customerCurrencies() {
      return this.$store.state.misc.customerCurrencies.map((e) => ({
        id: e.code,
        text: e.code,
      }));
    },

    messengers() {
      return this.$store.state.misc.messengers.map((e) => ({
          id: e.id,
          text: e.name,
        })).concat({
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        });
    },

    disabledSubmit() {
      return (
        this.model.password !== this.password2 ||
        this.model.password === '' ||
        this.model.email === '' ||
        this.model.first_name === '' ||
        this.model.last_name === '' ||
        this.model.messenger === '' ||
        this.model.messenger_id === '' ||
        this.model.country_code === '' ||
        this.model.date_of_birth === '' ||
        this.payouts !== true
      );
    },

    countries() {
      return this.$store.state.misc.countries.map((e) => {
        e.id = e.code;
        e.text = `${e.name} - ${e.name_native}`;
        return e;
      });
    },

    valid_email() {
      const _email = this.$v.model.email;
      return _email.email && _email.required;
    },

    error() {
      return this.$store.state.errors.request >= 400;
    },
    logo() {
      return this.$config.layout.logoFull;
    },
  },

  watch: {
    model(v) {
      if (this.model.phone_number && this.tel.setNumber) {
        this.tel.setNumber(v.phone_number, intlTelInputUtils.numberFormat.INTERNATIONAL);
        this.model.phone_number = this.tel.getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL);
      }
    },
    'model.messenger': {
      handler(value) {
        if (value && this.model.messenger_id) {
          this.model.messenger_id = '';
        }
      },
    },
  },

  methods: {
    verifyV2(token) {
      this.recaptchaTokenV2 = `google_recaptcha_v2__${token}`;
    },
    async getGeoData() {
      try {
        await this.$store.dispatch('getGeoData');
        this.model.country_code = this.$store.state.misc.geoData.country_code;
        this.model.city = this.$store.state.misc.geoData.city_name;
      } finally {
        this.initTel();
      }
    },

    async getCustomerCurrencies() {
      await this.$store.dispatch('getCustomerCurrencies');
      this.model.currency_code =  this.$store.state.misc.customerCurrencies.find(el => !!el.is_default)?.code;
    },

    async recaptcha() {
      try {
        if (await this.$recaptchaLoaded()) {
          const token = await this.$recaptcha('registration');
          this.recaptchaTokenV3 = `google_recaptcha_v3__${token}`
        }
      } catch (error) {
        console.log(error);
      }
    },

    async handlerSubmit({ isBtnClick = false } = {}) {
      this.resetError();

      if (this.model.password !== this.model.password2) {
        this.errors_.password2 = [{
          message: this.$t('profile.changePassword.dontMatch'),
        }]

        this.errors_.password = [{
          message: this.$t('profile.changePassword.dontMatch'),
        }]

        return;
      }

      this.loading = true;
      this.model.referred_by = cookieProvider.get('referred_by') || '';

      await this.recaptcha();
      const recaptcha_token = this.recaptchaTokenV2 || this.recaptchaTokenV3;

      try {
        const data = {
          ...this.model,
          phone_number: this.tel?.getNumber(intlTelInputUtils.numberFormat.E164),
          ...(recaptcha_token && { recaptcha_token }),
        };
        await this.$api.postAuthRegister(data);
        this.$router.push('/registration-confirm');
      } catch (error) {
        const { errors } = error.response.data
        this.errors_ = { ...this.errors_, ...errors };

        if ((Object.keys(errors).length === 1 && errors.hasOwnProperty('recaptcha_token'))) {
          if (!this.recaptchaTokenV3 || (isBtnClick && !this.recaptchaTokenV2)) {
            await this.handlerSubmit();
            return;
          }

          if (!this.recaptchaTokenV2) {
            this.isCaptchaV2Required = true;
            this.loading = false;
            return;
          }

          this.$alert.error(this.$t('alerts.captchaRequestFailed'));
        }

        this.loading = false;

      } finally {
        this.$refs.captchaV2?.reset()
        this.recaptchaTokenV2 = null;
      }
    },

    formatTel() {
      this.errors_.phone_number = '';
      this.model.phone_number = this.tel.getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL);
    },

    initTel() {
      const phone$el = this.$refs.phoneInput.$el.querySelector('input');
      this.tel = TelInput(phone$el, {
        initialCountry: this.model.country_code,
        formatOnDisplay: true,
        nationalMode: true,
        autoPlaceholder: true,
        autoHideDialCode: true,
      });
    },

    resetError() {
      this.errors_ = {
        email: '',
        password: '',
        password2: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        phone_number: '',
        country_code: '',
        city: '',
        messenger: '',
        messenger_id: '',
        referred_by: '',
        recaptcha_token: '',
        address: '',
        postal_code: '',
      };
    },
  },
  validations: {
    model: {
      password: {
        required,
        minLength: minLength(8),
      },
      email: {
        required,
        email,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.captcha {
  display: flex;
  justify-content: center;
  margin-top: -12px;
  margin-bottom: 16px;
}

.text-danger {
  &.ui-m-sm-t {
    margin-top: 4px!important;
  }
}

.v-select {
  /deep/ {
    .select2-container.select2 {
      z-index: 0;
    }
    .selection .select2-selection {
      border: none !important;
      background: transparent;
      border-bottom: 2px solid #ccc !important;
      border-radius: initial;
      padding: 11px 30px 5px 1px;
      height: fit-content;

      .select2-selection__rendered {
        padding-left: 0;
        color: #000;
      }

      .select2-selection__placeholder {
        color: #7d7d7d !important;
      }

      .select2-selection__arrow {
        height: 100%;
        color: #6c757d !important;
        opacity: 0.3;
      }
    }

    .select2-container--open .select2-selection {
      border-color: var(--color-bg-primary-hover) !important;
    }
  }
}
</style>

<style lang="scss">
@import '~flatpickr/dist/flatpickr';
</style>
