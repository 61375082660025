<template>
  <section class="cpa-conversions">
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :count="$store.state.report.cpa_conversions_count"
      :formaters="formaters"
      :header="$t('cpaConversions.title')"
      dont-fill-empty
      extend-height
      i18n-path="reports"
      @fetch-data="fetchDataTable"
    >
      <div
        slot="header"
        :class="[
          'ui-mobile-12',
          'ui-mobile-wrap',
          'ui-d-flex'
        ]"
      >
        <ui-filters
          :use-filters="availableFilters"
          :filters="filters"
          :route="route"
          @input="filterCpaConversions"
          @reset="reset"
        />
      </div>
    </table-card>
  </section>
</template>

<script>
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import SortBy from 'src/components/SortBy.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import {
  pageSizeMixin,
  resolvePageSize,
} from 'src/views/Dashboard/Reports/page_size.js';
import TableCard from 'src/components/Cards/TableCard.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import Tooltip from 'src/components/Tooltip.vue';
import formatCurrency from '@/mixins/format-currency';
import UiFilters from 'src/components/UiFilters/UiFilters.vue';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = '/cpa-conversions';
const LS_KEY = '/dashboard/cpa-conversions';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const defaultParams = {
  page: 1,
  pageSize,
  sort_column: 'incrementing_id',
  sort_dir: 'desc',

  site_id: JSON.parse(localStorage.getItem(LS_KEY))?.site_id || 'all',
  affiliate_offer_id: JSON.parse(localStorage.getItem(LS_KEY))?.affiliate_offer_id || 'all',
  cpa_status: JSON.parse(localStorage.getItem(LS_KEY))?.cpa_status || [],
};

const fetch = (ctx, params) => {
  if (ctx.$_.isEmpty(params)) {
    params = {
      ...defaultParams,
    };
  }

  const { site_id, affiliate_offer_id, cpa_status, ...restParams } = params;

  const cpaConversions = () => ctx.$store.dispatch('getCpaConversions', {
    offset: params.offset || 0,
    sort_dir: params.sort_dir || 'desc',
    sort_column: params.sort_column || 'incrementing_id',
    ...(site_id && site_id !== 'all' && { site_id }),
    ...(affiliate_offer_id && affiliate_offer_id !== 'all' && { affiliate_offer_id }),
    ...(cpa_status && cpa_status.length !== 0 && { status: cpa_status }),
    ...restParams,
    limit: ctx.pageSize || pageSize,
  });

  return Promise.all([cpaConversions()]);
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  windowRefreshHook: true,
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    pageSizeMixin,
    tableFlowMixin,
    formatCurrency,
  ],
  fetch,
  components: {
    Mobtable,
    Mobpagination,
    SortBy,
    TableCard,
    Tooltip,
    UiFilters,
  },
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      route: 'cpaConversions',
      pagination: 'true',
      availableFilters: ['brand', 'offers', 'cpaStatus'],
      filters: this.getDefaultFilter(),
      columns: [
        {
          name: 'incrementing_id',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          minWidth: 60,
        },
        {
          name: 'site_name',
          width: '188',
          align: 'left',
          renderValue: (val, row) => {
            const img = row.site_favicon_base64 ? `<img class="ui-m-sm-r" src="${row.site_favicon_base64}"/>` : '';

            return `<div style="overflow: hidden" class="ui-d-flex ui-ai-center">${img}<div class="text">${row.site_name}</div></div>`;
          },
        },
        {
          name: 'site_player_id',
          minWidth: '110',
          class: 'text',
        },
        {
          name: 'affiliate_offer_name',
          minWidth: '186',
          class: 'text',
        },
        {
          name: 'status',
          width: '120',
          class: 'text',
          computedClass: val => (this.statusMap[val] || {}).class,
          renderValue: (val) => {
            const { label = '' } = this.statusMap[val] || {};
            return label;
          },
        },
        {
          name: 'revenue',
          headerAlign: 'right',
          align: 'right',
          width: '92',
          renderValue: ((val, row) => this.currencyFormatter(val, row.currency_code)),
        },
        {
          name: 'hold_expires_at',
          width: '180',
          sortable: 'custom',
          format: 'date-time',
          class: 'text',
        },
      ],
      formaters: Formaters,
    };
  },
  computed: {
    cacheData() {
      return {
        page: this.page,
        pageSize: this.pageSize,
        offset: this.offset,
        limit: this.limit,
        sort_column: this.sort_column,
        sort_dir: this.sort_dir,
        site_id: this.filters.site_id,
        affiliate_offer_id: this.filters.affiliate_offer_id,
        cpa_status: this.filters.cpa_status,
      };
    },
    statusMap() {
      return {
        pending_qualification: {
          label: this.$t('mediaBuying.payments.status.pending_qualification'),
        },
        in_hold: {
          label: this.$t('mediaBuying.payments.status.in_hold'),
          class: 'text-info',
        },
        on_review: {
          label: this.$t('mediaBuying.payments.status.on_review'),
          class: 'text-orange',
        },
        approved: {
          label: this.$t('mediaBuying.payments.status.approved'),
          class: 'text-green',
        },
        declined: {
          label: this.$t('mediaBuying.payments.status.declined'),
          class: 'text-red',
        },
      };
    },
    rows() {
      return this.$store.state.report.cpa_conversions;
    },
  },
  methods: {
    initFilter(query) {
      const filters = this.$_.pick(query, ['site_id', 'affiliate_offer_id', 'cpa_status']);

      this.filters = {
        ...this.filters,
        ...filters,
      };
    },

    async fetchDataTable(params) {
      await this.fetchData({
        ...this.filters,
        ...params,
      });
    },
    async filterCpaConversions(data) {
      this.filters = {
        ...this.filters,
        ...data,
      };
      this.page = 1;
      await this.fetchData({
        ...this.cacheData,
        ...this.filters
      });
    },
    async reset() {
      this.filters = this.getDefaultFilter();
      await this.fetchData({
        ...this.cacheData,
        ...this.filters
      });
    },
    getDefaultFilter() {
      return {
        site_id: 'all',
        cpa_status: [],
        affiliate_offer_id: 'all',
      };
    },
    currencyFormatter(amount, code) {
      if (!amount && amount !== 0) return;

      amount = amount.toFixed(2);
      const amountData = amount.split('.');
      const result = (+amountData[0]).toLocaleString();

      return `${this.getCurrencyLabel(code)}${result}.${amountData[1]}`;
    },
  },
  created() {
    this.$store.dispatch('getAffiliateOffers', {
      sort_column: 'incrementing_id',
      sort_dir: 'asc',
    })

    this.initFilter(this.passedParams);
    this.setFromQuery(this.passedParams);
  },
};
</script>

<style lang="scss">
.cpa-conversions {
  min-height: calc(100vh - 100px);

  > .card {
    min-height: calc(100vh - 100px);
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
  }

  .head-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 650px) {
    > .card {
      padding: 0 !important;
    }

    .mob-wrap {
      .castom-margin-bottom:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
      }

      .actions a:last-child,
      .btn.text-green {
        border-left: 1px solid #e7ecf1;
      }
    }

    .head-wrap {
      padding: 6px 16px 16px;
      flex-direction: column;
      align-items: baseline;

      .btn-primary {
        margin: 0 !important;
        width: 100%;
      }

      > .d-flex {
        display: block !important;
        width: 100%;
        margin-top: 10px;
      }

      .the-pagination {
        margin-top: 10px;
      }
    }

    .sub-aff__right-text {
      > div {
        justify-content: flex-end;
      }
    }
  }
}
</style>
