<template>
  <div
    class="select-block-wrap select-block-wrap__nb"
  >
    <span class="pl-16">
      {{filterElement.title}}
    </span>
    <div v-if="!filterElement.multiple" class="el-select__wrap">
      <v-select
        class="el-input el-input-select"
        v-model="filterElement.value"
        :options="filterElement.options"
      />
    </div>
    <ui-select
      v-else
      ref="select"
      :value="[...filterElement.value]"
      :options="filterElement.options"
      :placeholder="filterElement.placeholder"
      :default-value="[]"
      width="auto"
      value-prop="id"
      label-prop="text"
      :multiple="true"
      class="filter"
      @select="handleSelect"
    />
  </div>
</template>

<script>
export default {
  name: 'UiFiltersItem',

  props: {
    filterElement: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    handleSelect(val) {
      this.$refs.select.isOpen = false
      this.filterElement.value = val;
    },
  }
};
</script>
<style lang="scss" scoped>
  .filter.ui-select {
    /deep/ {
      .pop {
        right: 0;
        width: 323px;

        @media (max-width: 650px) {
          width: 317px;
        }
      }

      .options {
        font-size: 14px;
        font-family: 'Open Sans', serif;

        .tag .label {
          font-weight: 700!important;
        }
      }

      .control-wrapper {
        border: none;

        .tags {
          max-width: 142px;
          font-size: 12px;
          font-family: 'Open Sans', serif;

          .label {
            font-size: 12px;
          }
        }

        .placeholder {
          width: 100%;
          margin-right: 2px;
          text-align: right;
          color: inherit!important;
        }
      }
    }
  }
</style>
